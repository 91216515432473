import { useState, useRef, useEffect } from 'react';
import { useAccount } from 'wagmi'
import ReactPlayer from 'react-player'
import { styled, darken } from '@mui/material/styles';

import {
  useNavigate,
} from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { Paper, Button, Typography, TextField, Box } from '@mui/material';

import { DataStore } from '@aws-amplify/datastore';
import { SecretContent, SecretAccessToken } from '../models';
import { sendAmplitudeData } from '../services/amplitude';
import { Profile } from '../components/Login';

const StyledButton = styled(Button)`
  background-color: #08E9D6;
  color: #001624;
  padding: 7px;
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 5px;
  border-radius: 0.5rem;
  text-transform: none;

  :hover {
    background-color: ${darken('#08E9D6', 0.2)};
  }
`;

const StyledPaper = styled(Paper)`
  background-color: #001624;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MainPaper = styled(Paper)`
  height: 90vh !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0C212E;
`;

const StyledBlock = styled("div")`
  margin: auto;
  width: 60%;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const StyledSection = styled(Box)`
  margin-left: 1rem;
  margin-right: 1rem;
  // width: 25%;
`;

const MainHeader = styled(Typography)`
  margin: 1rem;
  margin-bottom: 2.5rem;
  text-align: center;
  font-family: 'Inter';
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 3.75rem;
  letter-spacing: -0.02em;
  color: #fff;
`;

const SecondaryHeader = styled(Typography)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #fff;
  font-family: 'Inter';
`;

const BodyText = styled(Typography)`
  color: #fff;
  font-family: 'Inter';
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

const SCheckIcon = styled(CheckIcon)`
  font-size: 1rem;
  color: #08E9D6;
`;

function Main() {
  const [token, setToken] = useState('');
  const [film, setFilm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);

  const inputRange = useRef(null);

  const { data } = useAccount()

  const navigate = useNavigate();

  if(data) {
    navigate("/wallet");
  }

  const verifyToken = async () => {
    const accessTokenRecord = (await DataStore.query(SecretAccessToken)).filter(t => { console.log(t.token, 't.token'); return t.token === token });
      // console.log(accessTokenRecord, 'accessTokenRecord')
      if(accessTokenRecord[0]) {
        const models = await DataStore.query(SecretContent, accessTokenRecord[0]?.secretcontentID );
        // console.log(models, 'models')
        if(models?.video){
          setFilm(models)
          setIsOpen(true)
          const eventProperties = {
            videoName: models.videoName,
            videoId: models.id,
            usedToken: token,

          };
          // console.log('Access Token Verificaiton', eventProperties);
          sendAmplitudeData('Access Token Verificaiton', eventProperties);
        } else {
          setIsError(true)
        }
      } else {
        setIsError(true)
      }
  }

  return (
    <div className="App">
      {isOpen ?
      <StyledPaper>
        <StyledBlock>
        <StyledButton onClick={() => { setIsOpen(false) }}>Back <ArrowBackIcon /></StyledButton>
        <ReactPlayer
          onStart={() => { 
            const eventProperties = {
              videoName: film.videoName,
              videoId: film.id,
              usedToken: token,
            };

            // console.log('Play NFT Video', eventProperties)
            sendAmplitudeData('Play NFT Video', eventProperties);
          }}
          ref={inputRange}
          className='react-player'
          url={film.video}
          controls={true}
          playing={false}
        />
        </StyledBlock>
      </StyledPaper>: (
        <>
          <MainPaper>
            <div style={{ maxWidth: '40rem' }}>
            <MainHeader variant="h3" sx={{ textAlign: 'left', fontSize: { xs: '1.75rem',  sm: '3rem'}, lineHeight: { xs: '2.25rem',  sm: '3.75rem' }, marginBottom: { xs: '1rem',  sm: '2.5rem' }  }}>Welcome to your <br /> Paus NFT Content</MainHeader>
            <Box sx={{display: "flex", flexDirection: 'column', justifyContent: "center", marginBottom: { xs: '1rem',  sm: '2rem'}}} >
              <StyledSection sx={{ marginBottom: { xs: '1.5rem',  sm: '3rem'} }}>
              <BodyText sx={{ fontSize: { xs: '1rem', sm: '1.125rem'}, lineHeight: { xs: '1.5rem', sm: '1.75rem'}}} variant="body1">Connect Your Wallet to see all exclusive content you have access! <br /> <br />
              </BodyText>
              <div style={{ display: 'flex', justifyContent: 'center'}}><Profile isOpenLogin={isOpenLogin} setIsOpenLogin={setIsOpenLogin} /></div>
              </StyledSection>
              <StyledSection>
                <SecondaryHeader variant="h5" sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginBottom: { xs: '1rem',  sm: '1.5rem'} }}>How to access my exclusive content using Exclusive token?</SecondaryHeader>
                <BodyText variant="body1" sx={{  fontSize: '1rem', lineHeight: '1.5rem', fontWeight: '500' }}>1. Insert your token</BodyText>
                <BodyText variant="body1" sx={{ fontSize: '1rem', lineHeight: '1.5rem', fontWeight: '500' }}>2. Verify</BodyText>
                <BodyText variant="body1" sx={{ fontSize: '1rem', lineHeight: '1.5rem', fontWeight: '500' }}>3. Watch</BodyText>
              </StyledSection>
            </Box>
            <Box component="form" sx={{ marginLeft: "1rem", marginRight: "1rem", marginBottom: '1rem' }}>
                <div>
                <SecondaryHeader variant="h5" sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginBottom: { xs: '1rem',  sm: '1.5rem'} }}>Token Verification</SecondaryHeader>
                  <TextField
                    required
                    id="id-token"
                    // label={!isError ? "Token Verification" : "Not Valid Token"}
                    type="text"
                    placeholder="Insert Token Here"
                    helperText={!isError ? "Find your token in the unlockable content in your NFT" : "Not Valid Token"}
                    error={isError}
                    sx={{
                      marginRight: '1rem',
                      borderRadius: '0.5rem',
                      fontFamily: 'Inter',
                      width: {
                        xs: '100%',
                        sm: '23rem'
                      },
                      marginBottom: '1rem',
                      // bgcolor: '#fff',
                      // '& label.Mui-focused': {
                      //   color: '#001624',
                      // },
                      '& .MuiFormHelperText-root': {
                        color: '#fff',
                        fontFamily: 'Inter',
                        fontSize: '0.75rem',
                        lineHeight: '1.125rem',
                        marginLeft: '0px',
                        marginTop: '1rem'
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                      '& .MuiOutlinedInput-root': {
                          bgcolor: '#fff',
                          borderRadius: '0.5rem',
                          fontFamily: 'Inter',
                          textAlign: 'center',
                          '& .MuiOutlinedInput-input': {
                            paddingY: '0.625rem',
                            textAlign: 'center'
                          },
                        '&.Mui-focused fieldset': {
                          borderColor: '#99A2A7',
                        },
                      },
                    }}
                    value={token}
                    onChange={(event) => { setToken(event.target.value); setIsError(false) }}
                    onPaste={(event) => { event.preventDefault(); setToken(event.clipboardData.getData('Text')); setIsError(false) }}
                  />
                  <Button 
                    sx={[{
                      height: '2.65rem',
                      borderRadius: '0.5rem',
                      backgroundColor: '#08E9D6',
                      color: '#000',
                      fontFamily: 'Inter',
                      paddingLeft: '25px',
                      paddingRight: "25px",
                      textTransform: 'none',
                      '&.Mui-disabled ': {
                        backgroundColor: '#9CF6EF',
                        color: '#06BFB2',
                        cursor: 'not-allowed',
                      },
                      width: '100%',
                      width: {
                        xs: '100%',
                        sm: '7rem'
                      }
                    },
                    {
                      '&:hover': {
                        backgroundColor: darken('#08E9D6', 0.2),
                      },
                    },
                  ]}
                  disabled={!token}
                  onClick={() => verifyToken()}
                  >
                    Verify <ArrowForwardIcon />
                  </Button>
                </div>
              </Box>
              </div>
          </MainPaper>
        </>
      ) }
    </div>
  );
}

export default Main;
