import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { Button, Modal, Box, Typography } from '@mui/material';
import { darken } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import img from '../assets'

import {
  useNavigate,
} from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  borderRadius: '15px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TertiaryButton = styled(Button)`
  color: #334550;
  background: #FFFFFF;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 0.063rem solid #99A2A7;
  box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
  border-radius: 0.375rem;
  &:hover {
    background: #E5E8E9;
    border: 0.063rem solid #99A2A7;
  }
  &:active {
    transform: perspective(0.063rem) scale(.9);
  }
  &:focus {
    background: #FFFFFF;
    border: 0.063rem solid #99A2A7;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05), 0rem 0rem 0rem 0.25rem #E6E8E9;
  }
  &:disabled { 
    background: #FFFFFF;
    pointer-events: unset; 
    cursor: not-allowed;
    border: 0.063rem solid #B3B9BD;
    box-sizing: border-box;
    color: #99A2A7;
  }
`;


const SecondaryHeader = styled(Typography)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-family: 'Inter';
  display: flex;
  justify-content: space-between;
`;

export function Profile({ isOpenLogin, setIsOpenLogin }) {
  const { connect, connectors, error, isConnecting, pendingConnector } =
  useConnect()

  return (
    <>
    <Button 
      sx={[{
        height: '2.65rem',
        borderRadius: '0.5rem',
        backgroundColor: '#08E9D6',
        color: '#000',
        fontFamily: 'Inter',
        paddingLeft: '25px',
        paddingRight: "25px",
        textTransform: 'none',
        '&.Mui-disabled ': {
          backgroundColor: '#9CF6EF',
          color: '#06BFB2',
          cursor: 'not-allowed',
        },
        width: '100%',
        width: {
          xs: '100%',
          sm: '10rem'
        }
      },
      {
        '&:hover': {
          backgroundColor: darken('#08E9D6', 0.2),
        },
      },
      ]}
      onClick={() => setIsOpenLogin(true)}
    >
      Connect Wallet
    </Button>
    {isOpenLogin && (
      <Modal
        open={isOpenLogin}
        onClose={() => setIsOpenLogin(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SecondaryHeader id="modal-modal-title" variant="h6" component="h2">
            <span>Select Wallet</span> <IconButton><CloseIcon onClick={() => setIsOpenLogin(false)} /></IconButton>
          </SecondaryHeader>
        <div>
      {connectors.map((connector, i) => (
        <TertiaryButton
          variant="outlined"
          width="100%"
          disabled={!connector.ready}
          key={connector.id}
          onClick={() => connect(connector)}
        >
          {img.length >i && <img src={img[i]} alt="Logo" width="35px" style={{ marginRight: '5px' }} />}
          {connector.name}
          {!connector.ready && ' (unsupported)'}
          {isConnecting &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
        </TertiaryButton>
      ))}

      {error && <div>{error.message}</div>}
    </div>
        </Box>
      </Modal>
    )}
    </>
  )
}
