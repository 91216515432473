// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { SecretAccessToken, SecretContent } = initSchema(schema);

export {
  SecretAccessToken,
  SecretContent
};