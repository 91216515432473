import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import '@aws-amplify/ui-react/styles.css'
import { AmplifyProvider } from '@aws-amplify/ui-react'
import { WagmiConfig } from 'wagmi'

import client from './utils/cryptoLogin';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsExports from "./aws-exports";
import { initAmplitude } from './services/amplitude';
//import { initHotjar } from './services/hotjar';
//import { initReactGA } from './services/ga';

Amplify.configure(awsExports);

initAmplitude();
//initHotjar();
//initReactGA();

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
    <AmplifyProvider>
        <App />
    </AmplifyProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
