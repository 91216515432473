
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

const alchemyId = process.env.ALCHEMY_ID || "OR0NGRWWEQDMHi8Q19vYnpucoc_lcSjH";
const alchemyUrl = process.env.ALCHEMY_URL || "https://eth-mainnet.alchemyapi.io/v2/";
const alchemyEndpoint = `${alchemyUrl}${alchemyId}`

const web3 = createAlchemyWeb3(alchemyEndpoint);

const exclusiveContractAddress = process.env.EXCLUSIVE_CONTRACT_ADDRESS || '0xd96266B431A18916Df3a9C416EE9D40063e6e5D3';

const limitedContractAddress = process.env.LIMITED_CONTRACT_ADDRESS || '0x3803FA35476bC76c3fC61b66E5aC461bd9E6CECa';

const getCollectionNFTs = async (owner) => {
    if (owner) {
        let data;
        try {
          data = await web3.alchemy.getNfts({owner: owner, contractAddresses: [exclusiveContractAddress, limitedContractAddress]});
          console.log("GETNFTS: ", data)
          return data?.ownedNfts || [];
        } catch (e) {
          getCollectionNFTs(owner)
        }
        return data;
    }
}

const getNFTsMetadata = async (data) => {
    if (data) {
        const NFTs = await Promise.allSettled(data.nfts.map(async (NFT) => {
          const metadata = await web3.alchemy.getNftMetadata({
            contractAddress: NFT.contract.address,
            tokenId: NFT.id.tokenId
          })

                return {
                    id: NFT.id.tokenId,
                    contractAddress: NFT.contract.address,
                    title: metadata.metadata.name,
                    description: metadata.metadata.description,
                    attributes: metadata.metadata.attributes,
                    metadata: metadata
                }
            
        }))
        console.log(NFTs)
        let fullfilledNFTs = NFTs.filter(NFT => NFT.status == "fulfilled")
        console.log("NFTS", fullfilledNFTs)
        return fullfilledNFTs;
    } else {
        return null;
    }
}

export { getCollectionNFTs, getNFTsMetadata }
