import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { AppBar } from '@mui/material';

import Main from './pages/Main';
import Wallet from './pages/Wallet';
import './App.css';
import logo from './logo-e.svg'

function App() {
  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: '#001624',
          height: '10vh'
        }}
      >
        <img src={logo} onClick={() => {  }}  style={{ width: '15rem', margin: 'auto' }} />
      </AppBar>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="wallet" element={<Wallet />} />
    </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
