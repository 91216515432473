import { useState, useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi'
import { useNavigate, } from "react-router-dom";
import { Paper, IconButton, Typography, TextField, Box } from '@mui/material';
import { styled,  } from '@mui/material/styles';
import ReactPlayer from 'react-player'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// import * as IPFS from 'ipfs-core'
// import { create, urlSource } from 'ipfs-http-client'

import { getCollectionNFTs } from '../utils/fetchNFTs';

const MainPaper = styled(Paper)`
  height: 90vh !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  background-color: #0C212E;
  border-bottom: none;
`;

const MainHeader = styled(Typography)`
  margin: 1rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Inter';
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 3.75rem;
  letter-spacing: -0.02em;
  color: #fff;
`;

const SecondaryHeader = styled(Typography)`
  margin-bottom: 1rem;
  font-weight: bold;
  color: #fff;
  font-family: 'Inter';
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledBlock = styled("div")`
  width: 49%;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const BodyText = styled(Typography)`
  color: #fff;
  margin-bottom: 0.75rem;
  font-family: 'Inter';
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

const ButtonStyled = styled('button')`
  padding: 0.4rem 1rem;
  border-radius: 10px;
  margin: auto; 
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

function Walet() {
  const { data, isLoading } = useAccount()
  const { disconnect } = useDisconnect()
  const [address, setAddress] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if(!isLoading && data && !address) {
      setAddress(data?.address);
    } 
    if(!address && !isLoading && !data) {
      navigate('/')
    } 
  },[data, address, isLoading]);

  const [NFTs, setNFTs] = useState([]);
  const [media, setMedia] = useState([]);

  useEffect(async () => {
    if (address) {
      const data = await getCollectionNFTs(address)
      console.log(data, 'data')
      setNFTs(data)
    }
  }, [address])

  useEffect(async () => {
    const prom = NFTs.map((nft) => {
      return fetch(nft?.tokenUri?.raw).then((response) => {
        return response.json();
      })
    })

    Promise.allSettled(prom).then((items) => {
      let fullfilledNFTs = items.filter(item => item.status == "fulfilled").map(item => item.value)
      console.log("fullfilledNFTs", fullfilledNFTs)
      setMedia(fullfilledNFTs);
    }).catch((err) => {
      console.log(err);
    });
  },[NFTs])

  // useEffect(async () => {

  //   const ipfs = await create()
    
  //   const file = await ipfs.add(urlSource("http://ipfs.io/ipfs/QmRjXEjbS5V9w8GboJxbkjQ3tBiy9WtEoQAzrqT7WAiw48"))
  //   console.log(file, 'file')
  // }, [])

  return (
    <div className="App">
      <MainPaper>
        <Box sx={{ maxWidth: { xs:'90vw', sm: '90vw', md: '70vw' }, display: 'flex', flexDirection: 'column' }}>
          <MainHeader variant="h3" sx={{ textAlign: 'left', fontSize: { xs: '1.75rem',  sm: '3rem'}, lineHeight: { xs: '2.25rem',  sm: '3.75rem' }, marginBottom: { xs: '1rem',  sm: '2.5rem' }  }}>Paus NFT Content</MainHeader>
            <ButtonStyled onClick={() => { setAddress(''); disconnect(); navigate('/'); }} > Connected to {address} Disconnect</ButtonStyled>

          {media[0] ?(
            <Box sx={{ margin: "1rem", display: 'flex', alignItems: { xs:'center', sm: 'center', md: 'normal' }, flexDirection: { xs:'column',  sm: 'column', md: 'row' } }}>
              {media.map(item => {
                return (
                  <StyledBlock>
                    {item.animation_url ? (
                    <ReactPlayer
                      className='react-player'
                      url={item.animation_url}
                      controls={true}
                      playing={false}
                    />) : (
                    <div style={{ margin: 'auto', width: 'inherit' }}>
                      <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={item.image}></img>
                    </div>
                    )}
                    <SecondaryHeader>{item.name} <IconButton sx={{ color: '#fff' }}><OpenInNewIcon onClick={() => {window.location.replace(item.external_url)}} /></IconButton></SecondaryHeader>
                    <BodyText variant="body1" sx={{ fontSize: '1rem', lineHeight: '1.5rem', fontWeight: '500',  display: '-webkit-box', '-webkit-line-clamp': '5', '-webkit-box-orient': 'vertical', overflow: 'hidden' }}>{item.description}</BodyText>
                    <BodyText variant="body1" sx={{ fontSize: '0.85rem', lineHeight: '1.5rem', fontWeight: '300', textOverflow: 'ellipsis', overflow: 'hidden' }}>Artist: {item.artist}</BodyText>
                  </StyledBlock>);
              })}
            </ Box>
          ) : (
            <SecondaryHeader variant="h5" sx={{   textAlign: 'center', fontSize: '1.5rem', lineHeight: '1.5rem', marginTop: { xs: '1.5rem',  sm: '2rem'} }}><div style={{ margin: 'auto' }}>Buy NFTs at <a target="_blank" href="https://nft.paus.tv/">https://nft.paus.tv/</a></div></SecondaryHeader>
          )}
        </Box>
      </MainPaper>
    </div>
  );
}

export default Walet;